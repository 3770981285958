import IcomoonReact from 'icomoon-react';
import React from 'react';
import iconSet from './selection.json';

const Icon = (props) => {
  const { style, color, size = 24, icon = '', className = '' } = props;
  return icon ? (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={icon}
      style={{ ...style, display: 'block', boxSizing: 'border-box' }}
    />
  ) : null;
};

export default Icon;
